import { useMemo } from 'react';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { ExternalLink } from 'components/Links';

import { HeroContainer, Section, SectionImage } from 'components/Container';
import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { Cards } from 'components/Cards';
import { Notice } from 'components/Notice';
import { ContentBlock } from 'components/ContentBlock';
import { useBenefitsCards, getInfoCards, getStepCards } from 'page-data/affiliates';

import HeroImage from 'images/affiliates/hero.inline.svg';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const formLink = 'https://form.asana.com/?k=_CijELVpHo0kNsmxapaOQg&d=1200581835983208';

const Affiliates: FC<LocalizedPageProps> = () => {
  const {
    translations: { freeUsers },
  } = useContentfulLocale();

  const benefitsCards = useBenefitsCards();

  const infoCards = useMemo(() => getInfoCards({ freeUsers }), [freeUsers]);

  const stepCards = useMemo(() => getStepCards({ formLink }), []);

  return (
    <Layout>
      <Seo />

      <HeroContainer background="cream" skipColoration>
        <Section>
          <h1>
            Turn your influence <br /> into an income stream
          </h1>
          <p>
            Earn up to 50% commission from each subscription to Sharesight, the world’s leading
            portfolio tracker for self-directed investors.
          </p>

          <br />

          <ExternalLink asButton to={formLink} appearance="primary">
            Apply now
          </ExternalLink>
        </Section>

        <SectionImage image={<HeroImage />} boxShadow={false} />
      </HeroContainer>
      <Cards cards={stepCards} />
      <ContentBlock
        header="Who can become a Sharesight Affiliate?"
        textContent={
          <p>
            Whether you’re a dividend enthusiast or all-around finance genius, if you love the
            insights you gain from tracking your portfolio with Sharesight – and you think your
            followers would too – then we’d love to hear from you!
          </p>
        }
        image={useGatsbyImage({
          name: 'affiliates/influencers',
          alt: 'Bloggers, podcasters, youtubers, speakers',
        })}
      />

      <Cards cards={infoCards} />

      <Notice
        background="callout"
        header="Become a Sharesight Affiliate"
        subHeader="Help your followers to become better investors and start earning now!"
        button={
          <ExternalLink asButton to={formLink} appearance="primary">
            Apply now
          </ExternalLink>
        }
      />
      <Cards cards={benefitsCards} header="Benefits of becoming a Sharesight affiliate" />
    </Layout>
  );
};

export default Affiliates;
